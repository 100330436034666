import {
  Email,
  InvoiceDownload,
  CustomStatus,
  CourseName,
  RenderDate,
  Signed,
  DeclarationPreview,
} from "../containers/Corporate/BusinessUserLicense/CustomRender";
import HttpApis from "../services/HttpApis";
import { singleDeclarationFormSuccess } from "../redux/slices/declarationSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const useSingleDeclarationHook = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [downloadData, setDownloadData] = useState();
  const [declarationPreview, setDeclarationPreview] = useState(false);

  const singleDeclarationForm = async (params) => {
    try {
      setLoading(true);
      const response = await HttpApis.getSingleDeclarationForm(params);
      dispatch(singleDeclarationFormSuccess(response.data));
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const generateAndDownloadPDF = async (selector) => {
    const captureParent = document.querySelector(selector);
    captureParent.scrollTop = captureParent.scrollHeight;
    captureParent.style.opacity = 1;
    captureParent.style.height = "1px";
    captureParent.style.overflow = "auto";
    const capture = document.querySelector(`${selector} > div`);
    capture.style = {};
    var height = capture.offsetHeight;
    capture.style.width = `1300px`;
    capture.style.height = `${height}px`;
    html2canvas(capture).then((canvas) => {
      const doc = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("img/jpg");
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save("Declaration Form.pdf");
      captureParent.style.opacity = 0;
      captureParent.style.height = "1px";
      captureParent.style.overflow = "auto";
    });
  };
  useEffect(() => {
    if (downloadData) {
      setTimeout(() => {
        generateAndDownloadPDF(".declaration-form");
      }, 1000);
    }
  }, [downloadData]);

  const SignedbyyouTableCourse = [
    {
      label: "Date Added",
      mappedkey: "createdAt",
      defaultSortOrder: "descend",
      sorter: true,
      customRender: RenderDate,
    },
    {
      label: "Course Name ",
      mappedkey: "Course",
      customRender: CourseName,
    },
    {
      label: "Status",
      customRender: CustomStatus,
    },
    {
      label: "Preview",
      mappedkey: "Preview",
      action: (row) => {
        setDeclarationPreview(row);
      },
      customRender: DeclarationPreview,
    },
    {
      label: "Download",
      mappedkey: "Download",
      action: (row) => {
        setDownloadData(row);
      },
      customRender: InvoiceDownload,
    },
  ];

  const singledeclarationTableCourse = [
    {
      label: "Date Added",
      mappedkey: "createdAt",
      defaultSortOrder: "descend",
      sorter: true,
      customRender: RenderDate,
    },
    {
      label: "Course Name ",
      mappedkey: "Course",
      customRender: CourseName,
    },
    {
      label: "Manager’s Email",
      mappedkey: "email",
      customRender: Email,
    },
    {
      label: "Signed?",
      mappedkey: "Signed",
      customRender: Signed,
    },
    {
      label: "Status",
      customRender: CustomStatus,
    },
  ];

  return {
    SignedbyyouTableCourse,
    singledeclarationTableCourse,
    singleDeclarationForm,
    downloadData,
    declarationPreview,
    setDeclarationPreview,
    loading,
  };
};
