import styled from "styled-components";
import { Modal, Tag, Dropdown } from "antd";
import { color } from "../../../theme/Color";

export const Heading = styled.div`
  display: flex;
  gap: 9px;
  color: ${color._0F2A3C};

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  .number {
    color: ${color._6091a8};
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Pipe = styled.div`
  display: flex;
  background-color: ${color._6091a8};
  color: ${color._6091a8};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 2px;
  height: 20px;
  margin-top: 4px;
`;
export const LicenseContainer = styled.div``;
export const IssueButton = styled.div`
  .issue {
    border: none;
    width: 118px;
    height: 30px;
    border-radius: 2px;
    background: ${color._15374D};
    color: ${color._ffffff};
    display: flex;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    gap: 5px;
    justify-content: center;
    align-items: center;
  }
`;
export const RegulateHeading = styled.div`
  display: flex;
  justify-content: center;
`;
export const TableData = styled.div`
  margin-top: 26px;
  border-radius: 5px;
  border: 0.7px solid ${color._ededde};
  background: ${color._ffffff};
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
`;
export const DifferentContainers = styled.div`
  border-radius: 5px;
  margin-top: 92px;
`;
export const RegulatedCourseHeader = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 13px;
  .training {
    width: 23.402px;
    height: 27px;
    margin-top: 3px;
  }
`;
export const TitleCourse = styled.div`
  color: ${color._15374D};
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const TitleLine = styled.div`
  margin-top: 3px;
`;
export const Line = styled.div`
  height: 1px;
  background: ${color._eaedef};
  margin: 10px 0px;
`;
export const Border = styled.div`
  height: 4px;
  background: ${color._ffbe32};
`;
export const Title = styled.div`
  color: ${color._08232f};
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const RegulateMap = styled.div`
  display: flex;
  gap: 2%;
  margin-top: 30px;
  justify-content: space-between;
`;
export const CardText = styled.div`
  padding-bottom: 8px;
  color: ${color._767676};
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;
export const NumberBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 15px;
`;
export const CardNumber = styled.div`
  .call {
    color: ${color._6d7d8b};
  }
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${color._16384E};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const Lines = styled.div`
  display: flex;
  width: 0.5px;
  height: 18px;
  background: rgba(0, 0, 0, 0.1);
`;
export const PhoneNumber = styled.div`
  color: ${color._16384E};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const Email = styled.div`
  padding-top: 7px;
  .mail {
    color: ${color._6d7d8b};
  }
  display: flex;
  gap: 8px;
  font-size: 15px;
  align-items: center;
  color: ${color._08232f};
  padding-bottom: 10px;
  font-weight: 500;
  line-height: normal;
`;
export const CardButton = styled.div`
  padding-bottom: 10px;

  .btn {
    border-radius: 2px;
    background: ${color._ffc636};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    color: ${color._0F2A3C};
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const Items = styled.div`
  gap: 1.5rem;
  display: flex;
  margin: 0 auto;
  padding-bottom: 50px;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
export const SearchGSATContainer = styled.div`
  padding: 13px 26px 13px 20px;
  display: flex;
  gap: 14px;
`;

export const DropData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  outline: none;
  select {
    color: ${color._000000};
    outline: none;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-items: center;
    border: none;
    border-radius: 5px;
    background: none;
    padding: 0px 95px 0px 3px;
    width: 100%;
  }
  .placeholderValue {
    color: ${color._000000};

    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const IconGsat = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const DotIcon = styled.div`
  width: 38px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid ${color._ededde};
  background: ${color._ffffff};
  justify-content: center;
  display: flex;
  align-items: center;
`;
export const SearchContainer = styled.div`
  gap: 5px;
  .searchIcon {
    color: ${color._16384E};
    margin-top: 3px;
    margin-left: 6px;
  }
  display: flex;
  align-items: center;
  width: 210px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
`;

export const Input = styled.input`
  outline: none;
  border: none;
  flex-shrink: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${color._000000};
  &::placeholder {
    color: ${color._000000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const AntdTag = styled(Tag)`
  background: ${color._3ba32a};
  color: ${color._ffffff};
  width: 49px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const CourseColor = styled.div`
  color: ${color._5193aa};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
`;
export const AntdColor = styled.div`
  color: ${color._16384e};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const ForPointer = styled.div`
  color: ${color._16384e};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
`;

export const Invoice = styled.div`
  border-radius: 5px;
  color: ${color._15374d};
  font-size: 12px;
  display: flex;
  align-items: center;
  display: flex;
  gap: 3px;
`;

export const Download = styled.div`
  margin-left: 20px;
  border-radius: 5px;
  background: ${color._f1fbff};
  color: ${color._15374d};
  width: 29px;
  height: 29px;
  padding-left: 9px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const DownloadDisable = styled.div`
  margin-left: 20px;
  border-radius: 5px;
  background: ${color._ededee};
  color: ${color._767676};
  width: 29px;
  height: 29px;
  padding-left: 9px;
  font-size: 12px;
  display: flex;
  align-items: center;
`;
export const Status = styled.div`
  color: ${(props) =>
    props.text === "Approved" ? color._00BC35 : color._FFBE32};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: ${(props) =>
    props.text === "Approved" ? color._F1F7F1 : color._ffbe3224};
  text-align: center;
`;
export const Signed = styled.div`
  color: #15374d;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
`;
export const Completed = styled.div`
  color: ${color._15374d};
  font-size: 13px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
`;

export const EmailContent = styled.div`
  color: ${color._15374d};
  font-size: 13px;
  display: flex;
  align-items: center;
`;

export const CoursesDownload = styled.div`
  margin-left: 20px;
  border-radius: 5px;
  background: ${color._f1fbff};
  color: ${color._15374d};
  width: 29px;
  height: 29px;
  padding-left: 9px;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

export const Preview = styled.div`
  color: ${color._15374d};
  font-size: 13px;
  display: flex;
  cursor: pointer;
  text-decoration-line: underline;
`;

export const Labelcontainer = styled.div`
  padding-bottom: 19px;
  display: flex;
  gap: 38px;
`;

export const Error = styled.div`
  color: ${color._ff0000};
  font-weight: 400;
`;
export const InputField = styled.div`
  .labelStyled {
    color: ${color._565656};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 241px;
  }
  .labelStyled::after {
    content: "*";
    color: ${color._F00E00};
    font-size: 15px;
    font-weight: 400;
  }
  color: ${color._565656};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  .inputStyled {
    outline: none;
    width: 241px;
    height: 41px;
    color: ${color._000000};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px;
    background: rgba(233, 240, 243, 0.54);
    border: none;
    padding-left: 18px;
    text-transform: capitalize;
  }
  .emailInput {
    outline: none;
    width: 241px;
    height: 41px;
    color: ${color._000000};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px;
    background: rgba(233, 240, 243, 0.54);
    border: none;
    padding-left: 18px;
  }
`;
export const Addmoreusers = styled.div`
  padding-top: 150px;
  text-align: center;
  color: ${color._DADADA};
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 30px;
`;
export const FooterButton = styled.div`
  display: flex;
  justify-content: center;
  .invitebutton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    width: 199px;
    height: 33px;
    border: none;
    border-radius: 2px;
    background: ${color._0073de};
    color: ${color._ffffff};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
  }
  button:disabled {
    background: #a1afbb;
  }
`;
export const ADDButton = styled.div`
  .addIcon {
    font-size: 12px;
  }
  .addTrainee {
    gap: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border-radius: 2px;
    border: 1px solid rgba(20, 52, 74, 0.15);
    width: 122px;
    height: 30px;
    color: ${color._15374D};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const DropdownContent = styled.div`
  display: flex;
  gap: 5px;
  height: 36px;
  align-items: center;
  border-radius: 5px;
  padding: 0px 8px;
  .filter {
    font-size: 12px;
  }
  &.gsat {
    border: 1px solid ${color._ededee};
  }
`;
export const SignedText = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;

  .icon {
    font-size: 19px;
  }
  color: ${color._3ba32a};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  .text::after {
    content: "*";
    color: ${color._F00E00};
    font-size: 16px;
    font-weight: 400;
  }
`;
export const Text = styled.div`
  color: ${color._FFBE32};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  .text::after {
    content: "*";
    color: ${color._F00E00};
    font-size: 16px;
    font-weight: 400;
  }
`;
export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;
export const ButtonTextContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.isSpaceBetween ? "space-between" : "flex-end"};
  margin-top: 10px;
  padding-right: ${(props) => (props.isAddButtonClicked ? "0px" : "50px")};
`;
export const Declaration = styled.div`
  cursor: pointer;
  width: 223px;
  height: 25px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${color._0073de};

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 2px;
  background: rgba(0, 115, 222, 0.1);
`;
export const DeleteIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  .deleteicon {
    width: 16px;
    height: 18px;
    cursor: pointer;
  }
  .icon {
    font-size: 15px;
  }
  .inviteButton {
    margin-right: 39px;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 33px;
    border: none;
    border-radius: 2px;
    background: ${color._0073de};
    color: ${color._ffffff};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 7px;
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
  }
  button:disabled {
    background: #a1afbb;
  }
`;
export const CheckboxContainer = styled.div`
  .checkboxcontainer2 {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  .textConfirm {
    color: ${color._16384E};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .airsideText {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .checkboxInput2 {
    display: flex;
    gap: 10px;
    align-items: baseline;
  }
  .textSize {
    width: 90%;
    color: ${color._16384E};
    text-align: justify;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const Checkbox = styled.div`
  .checkboxInput {
    width: 17px;
    height: 17px;
  }
  .checkboxInput2 {
    margin-top: 2px;
    width: 17px;
    height: 17px;
  }
  .text {
    color: ${color._16384e};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .checkboxcontainer {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: 10px;
  padding-top: 25px;
`;
export const LabelBox = styled.div`
  display: flex;
  padding-top: 33px;
`;
export const LeftContainer = styled.div`
  flex: 0.6;
`;
export const DropdownContainer = styled.div`
  margin-top: 17px;
  color: ${color._000000};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px;
  background: ${color._f3f7f9};
  width: 241px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const RightContainer = styled.div`
  flex: 0.4;
  margin-left: 38px;
  display: flex;
  flex-direction: column;
  gap: 19px;
`;
export const Enroll = styled.div`
  padding-bottom: 20px;
  color: ${color._16384e};
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
export const UploadLine = styled.div`
  width: 813px;
  height: 1px;
  color: ${color._d9d9d9};
`;
export const AdduserLine = styled.div`
  width: 78px;
  height: 3px;
  background: ${color._0073de};
  margin-top: 17px;
`;
export const AdduserboxLine = styled.div`
  width: 813px;
  height: 1px;
  color: ${color._d9d9d9};
`;
export const AddUsers = styled.div`
  display: flex;
  gap: 1rem;
  .adduser {
    color: ${color._0073de};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .upload {
    color: ${color._16384e};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const AdduserLineBox = styled.hr`
  height: 1px;
  color: ${color._d9d9d9};
  margin: 0;
`;
export const DeclarationLine = styled.hr`
  height: 1px;
  color: ${color._d9d9d9};
  margin-top: 37px;
  margin-bottom: 25px;
`;
export const ModalContainer = styled(Modal)`
  & .ant-modal-content {
    padding: 32px 45px 50px 45px;
  }

  &.ant-modal {
    max-width: calc(100% - 32px);
  }
`;
export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled.div`
  width: 38px;
  height: 38px;
  background: ${color._EFF3F9};
  border-radius: 28px;
  cursor: pointer;
  margin-left: 2px;
  .closeIcon {
    height: 24px;
    width: 24px;
    color: ${color._6d7d8b};
    margin-top: 7px;
    margin-left: 7px;
  }
`;
export const InviteContainer = styled.div``;
export const LicenseData = styled.div`
  height: 410px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${color._ffffff};
  }

  &::-webkit-scrollbar-thumb {
    background: ${color._D9D9D9};
    border-radius: 4px;
  }
`;
export const DeclarationModal = styled(Modal)`
  .ant-modal-content {
    padding: 0px 0px 15px 0px;
  }
  &.ant-modal {
    max-width: calc(100% - 32px);
  }
`;
export const PreviewText = styled.div`
  color: ${color._0073DE};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 2px;
  background: rgba(0, 115, 222, 0.1);
  padding: 5px 12px;
  cursor: pointer;
`;
export const DeclarationData = styled.div`
  height: 410px;
  overflow-y: scroll;
  .paragraphData {
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.66);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .sign {
    color: ${color._4a4a4a};
    gap: 13px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .establish {
    margin-left: 22px;
  }
  .trader {
    margin-top: 15px;
  }
  .self-trader {
    margin-top: 6px;
  }
  padding: 28px 34px;
  color: rgba(0, 0, 0, 0.66);
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const HeaderIcon = styled.div`
  width: 38px;
  height: 38px;
  cursor: pointer;
  .closeIcon {
    height: 24px;
    width: 24px;
    color: ${color._ffffff};
    margin-top: 7px;
    margin-left: 7px;
  }
`;
export const Textcontainer = styled.div`
  padding: 22px 29px;
  .checkboxstyle {
    color: ${color._15374D};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const TextLine = styled.hr`
  color: ${color._D9D9D9};
  margin-top: 21px;
  margin-bottom: 28px;
`;
export const InputData = styled.input`
  height: 25px;
  width: 250px;
  border-radius: 3px;
  outline: none;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
`;

export const Tick = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .check {
    position: relative;
    right: 15%;
  }
`;

export const DeclarationContainer = styled.div`
  .headCorporate {
    display: flex;
    gap: 13px;
    align-items: center;
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .corporateIcon {
    align-items: center;
    padding: 23px;
    height: 75px;
    display: flex;
    justify-content: space-between;
    background: ${color._5193aa};
  }
`;

export const ActiveTag = styled(Tag)`
  border-radius: 3px;
  background: rgba(82, 218, 60, 0.24);
  width: 69px;
  height: 19px;
  color: ${color._3ba32a};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  justify-content: center;
  display: flex;
`;

export const IconContainer = styled.div`
  display: flex;
  gap: 15px;
`;
export const IconBg = styled.div`
  width: 22px;
  height: 22px;
  background: ${color._D1F0FF};
  border-radius: 15px;
  .icon {
    width: 16px;
    height: 16px;
    color: ${color._0090BD};
    margin-left: 2px;
    margin-bottom: 3px;
  }
`;
//upload CSV Style
export const BoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
export const UploaderContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const FileContainer = styled.label`
  width: 300px;
  padding: 12px;
  border: 2px dotted #16384e;
  margin-bottom: 25px;
  margin-top: 32px;
`;
export const FileText = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  .inputFile {
    display: none;
  }
  .icon {
    font-size: 24px;
  }
`;
export const ButtonContainer = styled.div`
  width: 144px;
  .downloadButton {
    border-radius: 2px;
    background: ${color._16384e};
    height: 34px;
    color: ${color._ffffff};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .previewButton {
    border-radius: 2px;
    background: ${color._16384e};
    width: 89px;
    height: 34px;
    color: ${color._ffffff};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const NoteText = styled.div`
  .note {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  color: ${color._16384E};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 20px;
`;
export const ButtonText = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 2px;
  background: ${color._16384e};
  height: 34px;
  color: ${color._ffffff};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ThreeDots = styled.div`
  width: 30px;
  height: 30px;
  background: ${color._6d7d8b1f};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color._6d7d8b};
  cursor: pointer;
  position: relative;
`;

export const SignatureContainer = styled.div`
  width: 100%;
  justify-content: end;
  display: flex;
  margin-top: 20px;
  padding: 0px 40px;
`;
