import styled from "styled-components";
import { color } from "../../theme/Color";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;
export const MainContainer = styled.div`
  margin-top: 75px;
  margin-bottom: 65px;
`;

export const Enroll = styled.div`
  color: ${color._ffffff};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }
`;
export const Head = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 80vw;
  height: 56px;
  border-radius: 2px;
  background: #007dd7;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);

  @media only screen and (max-width: 800px) {
    width: 100%;
    height: 100px;
  }
`;
export const Account = styled.div`
  padding: 20px 0px;
  display: flex;
  gap: 3%;
  align-items: center;
  @media only screen and (max-width: 800px) {
    display: block;
    .imgicon {
      display: flex;
      justify-content: center;
    }
  }
`;
export const Heading = styled.div`
  color: ${color._0F2A3C};
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 40px;
  }
`;
export const SubHead = styled.div`
  padding-top: 1px;
  color: ${color._5193aa};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }
`;
export const Buttons = styled.div`
  display: flex;
  color: ${color._5193aa};
  gap: 2%;
  align-items: center;
  .iconRight {
    position: relative;
    color: ${color._5193aa};
    left: 1%;
  }
  .singleBtn {
    justify-content: center;
    border-radius: 2px;
    border: 0.5px solid ${color._5193aa};
    color: ${color._5193aa};
    background: ${color._F3FBFF};
    width: 85px;
    height: 25px;
    display: flex;
    align-items: center;
    gap: 4px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
  }
  @media only screen and (max-width: 800px) {
    display: flex;
    margin-top: 9px;
    justify-content: center;
    .singleBtn {
      width: 232px;
      height: 43px;
      font-size: 22px;
    }
  }
`;
export const ImgIcon = styled.img`
  width: 73px;
  height: 84.226px;
  margin-top: 13px;
`;
export const SingleUser = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2% 0%;
  @media only screen and (max-width: 800px) {
    display: block;
    padding: 0px 10px 0px 45px;
  }
`;
export const SingleHead = styled.h6`
  color: ${color._0f2a3c};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  .FindOutMore {
    color: ${color._007cd7};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }
`;
export const SinglePara = styled.p`
  color: ${color._000000A8};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 32px;
    text-align: start;
  }
`;

export const SecondContainer = styled.div`
  margin-top: 1px;
  @media only screen and (max-width: 800px) {
    font-size: 20px;
  }
`;
export const CircleIcon = styled.span`
  margin-left: 20px;
  .icon {
    width: 30px;
    height: 30px;
    color: #16384e;
  }
`;

export const MainAccount = styled.div`
  width: 80vw;
  height: 496px;
  @media only screen and (max-width: 800px) {
    width: 230vw;
    height: 860px;
  }
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${color._eaedef};
  margin: 10px auto;
  @media only screen and (max-width: 800px) {
    margin: 36px auto;
  }
`;
export const RegisterBtn = styled.div`
  margin-top: 9px;
  .register {
    color: ${color._ffffff};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    justify-content: center;
    font-weight: 600;
    line-height: normal;
    width: 146px;
    height: 29px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 2px;
    background: #007dd7;
    border: none;
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
    @media only screen and (max-width: 800px) {
      background: ${color._007DD7};
      font-size: 20px;
      width: 285px;
      height: 53px;
      right: 34%;
      margin-top: 25px;
    }
  }
`;
export const SingleData = styled.div`
  margin-left: 2px;
`;

export const Footer = styled.div`
  width: 100%;
  height: 15vh;
  background: ${color._0f2a3c};
`;
