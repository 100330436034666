import AboutYou from "../components/Molecules/SignAboutyou/AboutYou";
import CheckDeclaration from "./../containers/BrowseTrainingDeclaration/CheckDeclaration";
import CompanyDetails from "../components/Molecules/SignAboutyou/CompanyDetails";
import DeclarationFormSingle from "./../containers/BrowseTrainingDeclaration/DeclarationFormSingle";
import FolderDeclaration from "./../containers/BrowseTrainingDeclaration/FolderDeclaration";
import PasswordYou from "../components/Molecules/SignAboutyou/PasswordYou";
import useAuthHook from "./AuthHook";
import { useState } from "react";
import { useSelector } from "react-redux";
import { constant } from "../constants/Constants";
import { CheckoutPage } from "../containers/Checkoutpage/CheckoutPage";
import { Welcome } from "../containers/InvitedUserSignUp/Welcome";
import AccountantDetail from "../containers/BrowseTrainingDeclaration/AccountantDetail";

const useStepper = () => {
  const [current, setCurrent] = useState(0);
  const [errors, setErrors] = useState({});
  const { handleChange, signUpData } = useAuthHook();
  const [finalSign, setFinalSign] = useState();

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = dd + "/" + mm + "/" + yyyy;
  const cartListData = useSelector((state) => state?.cartReducer?.cart?.data);
  const courseIds = [];

  cartListData?.map((item) => {
    if (item?.Course?.type === "AVSEC") {
      courseIds.push(item?.courseId);
    }
  });
  const [declareData, setDeclareData] = useState({
    name: "",
    role: "",
    backgroundCeck: false,
    cpsra: false,
    jobrole: "",
    formdate: today,
    sign: "",
    courseId: courseIds.toString(),
  });

  const [userConfirmation, setUserConfirmation] = useState({});
  const [emailData, setEmailData] = useState({
    email: "",
    declarationPdf: "",
  });

  const handleUserConfirmation = (e) => {
    const name = e.target.name;
    const value = e.target;

    if (name === "yearbackgroundcheck") {
      setUserConfirmation({ ...userConfirmation, [name]: value?.checked });
    } else {
      if (value?.value === "isEmployee") {
        setUserConfirmation({
          yearbackgroundcheck: userConfirmation.yearbackgroundcheck,
          isEmployee: 1,
          courseId: courseIds.toString(),
        });
        localStorage.setItem(
          "isEmployee",
          JSON.stringify({
            yearbackgroundcheck: userConfirmation.yearbackgroundcheck,
            isEmployee: 1,
            courseId: courseIds.toString(),
          })
        );
      } else {
        setUserConfirmation({
          yearbackgroundcheck: userConfirmation.yearbackgroundcheck,
          isRoleTrader: 1,
          courseId: courseIds.toString(),
        });
        localStorage.setItem(
          "isEmployee",
          JSON.stringify({
            yearbackgroundcheck: userConfirmation.yearbackgroundcheck,
            isEmployee: 0,
            courseId: courseIds.toString(),
          })
        );
      }
    }
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
    setUserConfirmation("");
    setErrors({});
  };

  const steps = [
    {
      title: "About You",
      content: (
        <AboutYou
          isCorporate
          signUpData={signUpData}
          handleChange={handleChange}
          errors={errors}
        />
      ),
    },
    {
      title: "Company Details",
      content: (
        <CompanyDetails
          isCorporate
          signUpData={signUpData}
          handleChange={handleChange}
          errors={errors}
        />
      ),
    },
    {
      title: "Password",
      content: (
        <PasswordYou
          signUpData={signUpData}
          handleChange={handleChange}
          registerButton={constant.Register}
          isCorporate
          errors={errors}
        />
      ),
    },
  ];

  const SignUpSteps = [
    {
      title: "About You",
      content: (
        <AboutYou
          isCorporate={false}
          signUpData={signUpData}
          handleChange={handleChange}
          errors={errors}
        />
      ),
    },
    {
      title: "Company Details",
      content: (
        <CompanyDetails
          isCorporate={false}
          signUpData={signUpData}
          handleChange={handleChange}
          errors={errors}
        />
      ),
    },
    {
      title: "Password",
      content: (
        <PasswordYou
          registerButton={constant.Register}
          signUpData={signUpData}
          handleChange={handleChange}
          isCorporate={false}
          errors={errors}
        />
      ),
    },
  ];

  const invitedSteps = [
    {
      title: "Welcome",
      content: <Welcome />,
    },
    {
      title: "Password",
      content: (
        <PasswordYou
          registerButton={constant.Register}
          signUpData={signUpData}
          handleChange={handleChange}
          errors={errors}
          isInvited={true}
        />
      ),
    },
  ];
  const declarationConfirm = [
    {
      id: "1",
      title: "User type confirmation",
      content: (
        <DeclarationFormSingle
          userConfirmation={userConfirmation}
          handleUserConfirmation={handleUserConfirmation}
        />
      ),
    },
    {
      id: "2",
      title: "Declaration",
      content:
        userConfirmation?.yearbackgroundcheck &&
        userConfirmation?.isEmployee ? (
          <CheckDeclaration
            errors={errors}
            emailData={emailData}
            setEmailData={setEmailData}
          />
        ) : (
          <FolderDeclaration
            declareData={declareData}
            setDeclareData={setDeclareData}
            errors={errors}
            setFinalSign={setFinalSign}
            finalSign={finalSign}
          />
        ),
    },
    {
      id: "3",
      title: "Accountant's Detail",
      content: (
        <AccountantDetail courseId={declareData?.courseId} next={next} />
      ),
    },
    {
      id: "4",
      title: "Payment",
      content: <CheckoutPage isPayment={true} />,
    },
  ];

  const orderConfirmation = [
    {
      title: "Declaration",
      content: (
        <FolderDeclaration
          isDeclaration={true}
          CorporateUser={true}
          declareData={declareData}
          setDeclareData={setDeclareData}
          errors={errors}
          setFinalSign={setFinalSign}
          finalSign={finalSign}
        />
      ),
    },
    {
      title: "Payment",
      content: <CheckoutPage isPayment={true} />,
    },
  ];

  const isEmployee = JSON.parse(localStorage.getItem("isEmployee"))?.isEmployee;
  const filteredDeclarationConfirm =
    isEmployee === 0
      ? declarationConfirm
      : declarationConfirm?.filter((step) => step?.id !== "3");

  return {
    SignUpSteps,
    next,
    prev,
    current,
    steps,
    setCurrent,
    invitedSteps,
    filteredDeclarationConfirm,
    orderConfirmation,
    signUpData,
    errors,
    finalSign,
    setErrors,
    declareData,
    userConfirmation,
    emailData,
    courseIds,
  };
};
export default useStepper;
