import * as Styled from "./style";
import Button from "../../components/Atoms/Button/button";
import Card from "../../components/Atoms/Cards/Card";
import Logo_darkcolor from "../../assets/Svg/Logo_darkcolor.svg";
import React from "react";
import { IoMdMail } from "react-icons/io";
import { IoMdCall } from "react-icons/io";
import { useSelector } from "react-redux";
import { GeneralSecurity } from "../../components/Atoms/Card/GeneralSecurity";
import { constant } from "../../constants/Constants";
import { useCourseDetails } from "../../hooks/CourseDetailsHook";
import { data, pageStats } from "./data";
import { Rate } from "antd";

export const Multiuser = () => {
  const { coursesDetails, handleClick } = useCourseDetails();
  const coursesData = useSelector(
    (state) => state?.coursesReducer?.courseDetails
  );
  const purchageDate = coursesData?.purchageDate?.split("T")[0];
  const alreadyPurchage = coursesData?.alreadyPurchage;
  const preCourseFilled = coursesData?.alreadyPrequestioner;
  const modules = coursesDetails?.course?.module?.split(",");
  const requirements = coursesDetails?.course?.requirement?.split(",");
  const declarationFormApproved = coursesData?.isDeclarationFormApproved;

  return (
    <Styled.Multiusercontainer>
      <div className="container">
        <Styled.MainContainer>
          <Styled.LeftCol>
            <div className="mblLeft">
              <Styled.Container>
                {data?.map((item, id) => {
                  return (
                    <Styled.ItemData href="" key={id}>
                      <div>{item?.name}</div>
                      <div>{item?.icon}</div>
                    </Styled.ItemData>
                  );
                })}
                <Styled.ItemData href="">
                  <Styled.NavUrlTex>
                    {coursesDetails?.course?.title}
                  </Styled.NavUrlTex>
                </Styled.ItemData>
              </Styled.Container>
              <Styled.LogoItem>
                <img
                  src={Logo_darkcolor}
                  alt="Logo_darkcolor"
                  className="logoDark"
                />
                <div>{coursesDetails?.course?.title}</div>
              </Styled.LogoItem>

              <Styled.StatsItem>
                <Styled.StatsData>
                  <div className="langIcon">{pageStats?.languageIcon}</div>
                  <div className="duration">
                    {coursesDetails?.course?.language}
                  </div>
                </Styled.StatsData>
                <Styled.StatsData>
                  <div className="langIcon">{pageStats?.durationIcon}</div>
                  <div className="duration">
                    {coursesDetails?.course?.duration}
                  </div>
                </Styled.StatsData>
                <Styled.StatsData>
                  <div className="langIcon">{pageStats?.certificationIcon}</div>
                  <div className="duration">
                    {coursesDetails?.course?.certificateStatus}
                  </div>
                </Styled.StatsData>
                <Styled.StatsData>
                  <div className="duration">{pageStats?.ratingIcon}</div>
                  <div>
                    <Rate
                      disabled
                      allowHalf
                      value={Number(coursesDetails?.course?.rating)?.toFixed(2)}
                    />
                  </div>
                  <div className="langIcon">
                    {Number(coursesDetails?.course?.rating)?.toFixed(2)}
                  </div>
                </Styled.StatsData>
              </Styled.StatsItem>
              <Styled.SpanDataitem>
                <Styled.StatsData>
                  <span className="valText">{pageStats?.locationLabel}</span>
                  <span className="activeText">
                    {coursesDetails?.course?.location}
                  </span>
                </Styled.StatsData>
                <Styled.StatsData>
                  <span className="valText">{pageStats?.assessmentLabel}</span>
                  <span className="activeText">
                    {coursesDetails?.course?.assessment}
                  </span>
                </Styled.StatsData>
                <Styled.StatsData>
                  <span className="valText">{pageStats?.validityLabel}</span>
                  <span className="activeText">
                    {coursesDetails?.course?.validity}
                  </span>
                </Styled.StatsData>
              </Styled.SpanDataitem>

              <Styled.Para>
                <div className="paragraph">
                  {coursesDetails?.course?.about}{" "}
                </div>
              </Styled.Para>
            </div>
          </Styled.LeftCol>

          <Styled.RightCol>
            <Styled.SecurityCard>
              <div className="card_Security">
                <GeneralSecurity
                  title={coursesDetails?.course?.title}
                  price={coursesDetails?.course?.price}
                  courseId={coursesDetails?.course?.id}
                  alreadyPurchage={alreadyPurchage}
                  purchageDate={purchageDate}
                  preCourseFilled={preCourseFilled}
                  slug={coursesDetails?.course?.slug}
                  declarationFormApproved={declarationFormApproved}
                  courseType={coursesDetails?.course?.type}
                  prequestionerStatus={coursesData?.prequestionerStatus}
                />
                {/* We can use the below code later */}
                {/* <Styled.TryLink>
                  <Styled.AlertIcon>
                    <TbAlertCircle className="alerticon" />
                  </Styled.AlertIcon>
                  <div>
                    <Styled.AlertText>{constant.Alert_text}</Styled.AlertText>
                    <Styled.AlertText className="try">
                      {constant.Try}
                      <Styled.AvtavLink>
                        <span className="linktext">{constant.Link_text}</span>
                        <span className="businessText">
                          {constant.BusinessText}
                          <FiArrowUpRight className="arrow" />
                        </span>
                      </Styled.AvtavLink>
                    </Styled.AlertText>
                  </div>
                </Styled.TryLink> */}
              </div>
            </Styled.SecurityCard>
          </Styled.RightCol>
        </Styled.MainContainer>
      </div>

      <Styled.ListContainer>
        <Styled.ModulesIncluded>
          <Styled.Module className="container">
            <Styled.LineContainer>
              <div className="line">{constant.About}</div>
              <Styled.Line></Styled.Line>
            </Styled.LineContainer>
            <Styled.ModulesContainer>
              <Styled.ModuleList>
                <Styled.ModulesSpan>
                  {constant.Modules_included}
                </Styled.ModulesSpan>
                <Styled.List>
                  {modules?.map((item, index) => {
                    return <li key={index}>Module : {item}</li>;
                  })}
                </Styled.List>
              </Styled.ModuleList>
              <div>
                <Styled.ModulesSpan>
                  {constant.Module_Requirements}
                </Styled.ModulesSpan>
                <Styled.List>
                  {requirements?.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </Styled.List>
              </div>
            </Styled.ModulesContainer>
          </Styled.Module>
        </Styled.ModulesIncluded>
      </Styled.ListContainer>
      <Styled.RelatedCourses>
        <Styled.Related>{constant.Related_Courses}</Styled.Related>
        <div className="line" />
      </Styled.RelatedCourses>
      <div className="relatedCourseWrapper">
        <Styled.CardSection>
          <Styled.Regulated>
            <img src={Logo_darkcolor} alt="Logo_darkcolor" className="dark" />
            <Styled.Regulatedtext>
              {coursesDetails?.course?.type}
            </Styled.Regulatedtext>
          </Styled.Regulated>
        </Styled.CardSection>
        <Styled.Card>
          {coursesDetails?.relatedCourses?.map((item) => {
            return (
              <Card
                data={item}
                children={
                  <>
                    <Styled.CardText>{item?.description}</Styled.CardText>
                    <Styled.NumberBlock>
                      <Styled.CardNumber>
                        <IoMdCall className="call" /> {item?.contactPhone}
                      </Styled.CardNumber>
                      <Styled.PhoneNumber>
                        {item?.contactMobile}
                      </Styled.PhoneNumber>
                    </Styled.NumberBlock>
                    <Styled.Email>
                      <IoMdMail className="mail" /> {item?.contactEmail}
                    </Styled.Email>
                    <Styled.CardButton>
                      <Button
                        className="buttonStyle"
                        onClick={() => handleClick(item?.slug)}
                        title={constant.BrowseText}
                      />
                    </Styled.CardButton>
                  </>
                }
              />
            );
          })}
        </Styled.Card>
      </div>
    </Styled.Multiusercontainer>
  );
};
