import HttpMethod from "./HttpMethod";

class HttpApis {
  async corporateSignUp(params) {
    return await HttpMethod._post("api/users", params);
  }

  async invitedUserSignUp(params) {
    return await HttpMethod._post("api/users/register-invited-user", params);
  }

  async getProfileData(params) {
    return await HttpMethod._get("api/users", params);
  }

  async userProfileUpdate(params) {
    return await HttpMethod._put("api/users", params);
  }

  async addToCart(params) {
    return await HttpMethod._post("api/cart", params);
  }

  async getCartList(userId, headers) {
    return await HttpMethod._get(
      "api/cart",
      { params: { userId: userId } },
      headers
    );
  }

  async deleteCart(id) {
    return await HttpMethod._delete(`api/cart/${id}`);
  }

  async qtyUpdate(id, params) {
    return await HttpMethod._post(`api/cart/${id}`, params);
  }

  async getCountry(params) {
    return await HttpMethod._get("api/country", params);
  }

  async getState(id) {
    return await HttpMethod._get(`api/state/${id}`);
  }

  async getCities(id) {
    return await HttpMethod._get(`api/city/${id}`);
  }

  async checkout(params, headers) {
    return await HttpMethod._post("api/checkout", params, headers);
  }

  async coursesList(params) {
    return await HttpMethod._get("api/courses", params);
  }

  async courseDetails(params) {
    return await HttpMethod._get(params);
  }

  async getPaymentToken(params) {
    return await HttpMethod._get("api/checkout/getToken", params);
  }

  async login(params) {
    return await HttpMethod._post("api/jwt", params);
  }

  async password(params) {
    return await HttpMethod._post("api/users/change-password", params);
  }

  async declarationForm(params) {
    return await HttpMethod._post("api/checkout/declaration-form", params);
  }

  async userTypeConfirmation(params) {
    return await HttpMethod._post(
      "api/checkout/declaration-single-step1-form",
      params
    );
  }

  async userTypeConfirmationEmail(params, headers) {
    return await HttpMethod._post(
      "api/checkout/declaration-single-step2-form",
      params,
      headers
    );
  }

  async getCorporateLicenses(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`api/users/licenses?${queryString}`);
  }

  async getCorporateDeclarationForm(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`api/users/declaration-form?${queryString}`);
  }

  async getWelcomeInvited(params) {
    const queryString = new URLSearchParams(params);
    return await HttpMethod._get(
      `api/users/licenses/welcome-user?${queryString}`,
      {}
    );
  }

  async issueLicense(params) {
    return await HttpMethod._post("api/users/licenses/invite-user", params);
  }

  async getLicensesTypeList() {
    return await HttpMethod._get("api/courses/type");
  }

  async postUplaodCSV(params) {
    return await HttpMethod._post("api/users/licenses/upload-csv", params);
  }

  async getBillingAddress(params) {
    return await HttpMethod._get("api/users/billing-address", params);
  }

  async updateBillingAddress(params) {
    return await HttpMethod._put("api/users/billing-address", params);
  }

  async getInvoiceAndPayment(params) {
    const queryString = new URLSearchParams(params);
    return await HttpMethod._get(`api/users/invoices-payments?${queryString}`);
  }

  async getSingleDeclarationForm(params) {
    const queryString = new URLSearchParams(params);
    return await HttpMethod._get(
      `api/users/declaration-form/single-user?${queryString}`
    );
  }

  async getMyCourse(params) {
    return await HttpMethod._get("api/users/my-courses/single-user", params);
  }

  async getIndividualCourse(params) {
    return await HttpMethod._get(
      "api/users/my-courses/single-user/individual",
      params
    );
  }

  async getPreCourseQuestionnaire(params) {
    const queryString = new URLSearchParams(params);
    return await HttpMethod._get(
      `api/precoursequestionnaire?sort=id|ASC&${queryString}`
    );
  }

  async postPreCourseQuestionnaire(params) {
    return await HttpMethod._post("api/precoursequestionnaire", params);
  }

  async postOrderCancelForm(params) {
    return await HttpMethod._post("api/users/cancel-order", params);
  }

  async getCorporateUser(params) {
    const queryString = new URLSearchParams(params);
    return await HttpMethod._get(`api/users/corporate-users?${queryString}`);
  }

  async deleteUser(id) {
    return await HttpMethod._delete(`api/users/corporate-users?id=${id}`);
  }

  async updateRole(params) {
    return await HttpMethod._put("api/users/corporate-users", params);
  }

  async licensesSignature(params) {
    return await HttpMethod._post("api/users/licenses/signature", params);
  }

  async getStaffSignature(id, params) {
    return await HttpMethod._get(`api/users/userDetails/${id}`, params);
  }

  async getNotifications(params) {
    return await HttpMethod._get(`api/users/notifications?limit=10`, params);
  }

  async getCompleteCourses(params) {
    const queryString = new URLSearchParams(params);
    return await HttpMethod._get(`api/training?${queryString}`);
  }
  async clearNotification(params) {
    return await HttpMethod._get(
      `api/users/notifications/clear-all-notification`,
      params
    );
  }

  async getFeedBackList(params) {
    return await HttpMethod._get(`api/feedbackforms`, params);
  }
  async postFeedBackForm(params) {
    return await HttpMethod._post(`api/feedbackforms`, params);
  }
  async getExamQuesList(slug) {
    return await HttpMethod._get(`api/users/my-courses/single-user/${slug}`);
  }
  async postExamQuesList(slug, params) {
    return await HttpMethod._post(
      `api/users/my-courses/single-user/${slug}`,
      params
    );
  }
  async postReviewApi(params) {
    return await HttpMethod._post("api/courses/review", params);
  }
  async getReviewListApi(courseName, params) {
    return await HttpMethod._get(`api/courses/review/${courseName}`, params);
  }
  async getInductionChecklist() {
    return await HttpMethod._get(`api/induction`);
  }
  async postInductionChecklist(params) {
    return await HttpMethod._post(`api/induction`, params);
  }
  async postDeclaration(params) {
    return await HttpMethod._post(`api/manager`, params);
  }
  async postQuiz(params) {
    return await HttpMethod._post(`api/courses/quiz`, params);
  }
  async certificatesList(params) {
    const queryString = new URLSearchParams(params);
    return await HttpMethod._get(`api/certificate?${queryString}`);
  }
  // slider API
  async postProgress(params) {
    return await HttpMethod._post(
      `api/users/my-courses/single-user/getProgress`,
      params
    );
  }

  async getProgressApi(courseId) {
    return await HttpMethod._get(
      `api/users/my-courses/single-user/getProgress?courseId=${courseId}`
    );
  }

  async postCheckList(params) {
    return await HttpMethod._post(
      `api/users/my-courses/course-check-list`,
      params
    );
  }

  async getCheckListApi(courseId) {
    return await HttpMethod._get(
      `api/users/my-courses/course-check-list?courseId=${courseId}`
    );
  }

  async getQuiz(courseId) {
    return await HttpMethod._get(
      `api/courses/quiz_result?courseId=${courseId}`
    );
  }

  async getCourseExpired(courseId) {
    return await HttpMethod._get(
      `api/courses/checkCourseExpired?courseId=${courseId}`
    );
  }

  async postAccountDetails(params) {
    return await HttpMethod._post(
      "api/checkout/declaration-single-soletrader-step3-form",
      params
    );
  }
}

export default new HttpApis();
