import * as Styled from "./style";
import React from "react";
import { ConstantInvoice } from "../data";
import dayjs from "dayjs";

const DownloadInvoice = ({ item }) => {
  const orderDate = item?.createdAt?.split("T")[0];
  const formatDate = dayjs(orderDate).format("DD-MM-YYYY");
  const itemOfInvoice = item?.OrderItems?.map((val) => {
    return val;
  });
  const vat = item?.vatTax ? item?.vatTax : 0;
  const total = parseInt(item?.totalAmount) - parseInt(vat);
  return (
    <Styled.MainContainer>
      <Styled.Invoice>
        <div className="invoice">{ConstantInvoice.invoice} </div>
        <Styled.InvoiceNumber>
          <div>{ConstantInvoice.no}</div>
          <div>{formatDate}</div>
        </Styled.InvoiceNumber>
        <div className="psNo">{item?.billingId}</div>
      </Styled.Invoice>
      <Styled.Client>
        <div className="client">{ConstantInvoice.client}</div>
        <div className="denmark">
          {item?.OrderBilling?.address}
          <br />
          {item?.OrderBilling?.City?.name}, {item?.OrderBilling?.State?.name}
          <br />
          {item?.OrderBilling?.Country?.name}, {item?.OrderBilling?.zipCode}
        </div>
      </Styled.Client>
      <Styled.TableData>
        <table className="invoiceTable">
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col">Description</th>
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {itemOfInvoice?.map((item) => {
              return (
                <>
                  <tr className="trow">
                    <td>{item?.id} </td>
                    <td>{item?.Course?.title} </td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{ __html: "&#163;" }}
                        className="code"
                      ></span>
                      &nbsp;
                      {item?.price}
                    </td>
                    <td>{item?.qty}</td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{ __html: "&#163;" }}
                        className="code"
                      ></span>
                      &nbsp;
                      {item?.price}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td className="subtotal">Subtotal</td>
            <td className="number">
              <span
                dangerouslySetInnerHTML={{ __html: "&#163;" }}
                className="code"
              ></span>
              &nbsp;
              {total}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td className="subtotal">VAT</td>
            <td>
              <div className="number">
                <span
                  dangerouslySetInnerHTML={{ __html: "&#163;" }}
                  className="code"
                ></span>
                &nbsp;
                {vat}
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td className="subtotal">Total</td>
            <td>
              <div className="number">
                <span
                  dangerouslySetInnerHTML={{ __html: "&#163;" }}
                  className="code"
                ></span>
                &nbsp;
                {item?.totalAmount}
              </div>
            </td>
          </tr>
        </table>
      </Styled.TableData>
    </Styled.MainContainer>
  );
};

export default DownloadInvoice;
