import { Dropdown, Progress } from "antd";
import * as Styled from "./style";
import * as dayjs from "dayjs";
import { MdDownload } from "react-icons/md";
import ThreeDots from "../../../components/Atoms/ThreeDots/ThreeDots";

export const TagRender = (tag) => {
  return <Styled.AntdTag bordered={false}>{tag}</Styled.AntdTag>;
};
export const TableDataColor = (text) => {
  return <Styled.AntdColor>{text}</Styled.AntdColor>;
};
export const BuyMoreText = (text, row, action) => {
  return (
    <Styled.ForPointer onClick={() => action(row)}>{text}</Styled.ForPointer>
  );
};
export const CourseColor = (text, row, action) => {
  return (
    <Styled.CourseColor onClick={action} style={{ cursor: "pointer" }}>
      {text?.title}
    </Styled.CourseColor>
  );
};

export const myCourseName = (text, row, action) => {
  return (
    <Styled.CourseColor
      onClick={() => action(row)}
      style={{ cursor: "pointer" }}
    >
      {text?.title}
    </Styled.CourseColor>
  );
};
export const TableInvoicePayment = (text) => {
  return (
    <Styled.Invoice>
      <span
        dangerouslySetInnerHTML={{ __html: "&#163;" }}
        className="code"
      ></span>
      {parseInt(text?.totalAmount)}
    </Styled.Invoice>
  );
};

export const InvoiceCourseName = (text) => {
  const invoiceName = text?.map((item) => {
    return item?.Course?.title;
  });
  return (
    <Styled.Invoice>
      {invoiceName.length > 1 ? (
        <span>{invoiceName.slice(0, 1).join(", ")}&nbsp;...</span>
      ) : (
        <span>{invoiceName.join(", ")}</span>
      )}
    </Styled.Invoice>
  );
};
export const InvoiceCourseNumber = (text) => {
  return <Styled.Invoice>{text?.length}</Styled.Invoice>;
};

export const Courses = (text) => {
  return <Styled.Completed>{text}</Styled.Completed>;
};

export const Email = (text) => {
  return <Styled.EmailContent>{text}</Styled.EmailContent>;
};

export const AccountType = (text) => {
  return (
    <Styled.Completed>
      {text == 1 ? "Self Purchased" : "Employer"}
    </Styled.Completed>
  );
};

export const Percentage = (text) => {
  return <Styled.Completed>{`${text} %`}</Styled.Completed>;
};

export const Signed = (text, row) => {
  return row?.isSigned === 0 ? (
    <Styled.Signed>{"Outstanding"}</Styled.Signed>
  ) : (
    <Styled.Signed>{"Signed"}</Styled.Signed>
  );
};
export const ExpirationDate = (text) => {
  const expirationDate = dayjs(text?.completion_date)
    .add(5, "year")
    .subtract(1, "day")
    .format("DD-MM-YYYY");

  return <Styled.Completed>{expirationDate}</Styled.Completed>;
};
export const CourseName = (text) => {
  return <Styled.Completed>{text?.title}</Styled.Completed>;
};
export const CustomStatus = (text, row) => {
  return row?.status === 0 ? (
    <Styled.Status>{"Pending"}</Styled.Status>
  ) : row?.status === 1 ? (
    <Styled.Status text={"Approved"}>{"Approved"}</Styled.Status>
  ) : (
    <Styled.Status>{"Rejected"}</Styled.Status>
  );
};
export const CoursesDownload = (text) => {
  return <Styled.CoursesDownload>{text}</Styled.CoursesDownload>;
};

export const ActiveTag = (tag) => {
  return <Styled.ActiveTag bordered={false}>{tag}</Styled.ActiveTag>;
};

export const CoursesDetail = (text, row, action) => {
  return (
    <Styled.Completed onClick={action} style={{ cursor: "pointer" }}>
      {text}
    </Styled.Completed>
  );
};

export const DeclarationPreview = (text, row, action) => {
  return (
    <Styled.Preview onClick={() => action(row)}>{"Preview"}</Styled.Preview>
  );
};
export const Action = (text, row, action) => {
  return <Styled.Preview onClick={() => action(row)}>{"View"}</Styled.Preview>;
};

export const InvoiceDownloadDisable = (text) => {
  return <Styled.DownloadDisable>{text}</Styled.DownloadDisable>;
};

export const InvoiceDownload = (text, row, action) => {
  return (
    <Styled.Download onClick={() => action(row)}>
      <MdDownload />
    </Styled.Download>
  );
};

export const RenderDate = (text) => {
  return (
    <Styled.Completed>{dayjs(text).format("DD-MM-YYYY")}</Styled.Completed>
  );
};

export const UserActions = (text, row, action) => {
  const items = [
    {
      label: "Manage Role",
      key: "0",
    },
    {
      label: "Delete User",
      key: "1",
    },
  ];

  const handleClick = (e) => {
    if (e.key == 1) {
      action.handleDelete(row);
    }
    if (e.key == 0) {
      action.handleManage(row);
    }
  };

  return (
    <Dropdown
      menu={{
        items,
        onClick: handleClick,
      }}
      trigger={["click"]}
    >
      <Styled.ThreeDots>
        <ThreeDots />
      </Styled.ThreeDots>
    </Dropdown>
  );
};

export const ProgressBar = (text) => {
  return <Progress percent={text} />;
};
